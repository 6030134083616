@tailwind base;
@tailwind components;
@tailwind utilities;

/* Write your own custom base styles here */
@layer base {
  html{
    @apply text-gray-900;
    font-size: 15px;
  }
  p {
    line-height: 1.5rem;
  }
  h1 {
    @apply text-2xl text-gray-800 font-semibold;
  }
  h2 {
    @apply text-xl text-gray-800 font-semibold;
  }
  h3 {
    @apply text-xl text-gray-800 font-semibold;
  }
  h4 {
    @apply text-lg text-gray-800 font-semibold;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}

@layer components {
  .nav-active{
    @apply font-semibold opacity-100 text-gray-800;
  }

  .btn-blue {
    @apply bg-blue-500 border-blue-600 border-2 text-white font-bold py-2 px-8 rounded opacity-100 transition duration-300;
  }

  .btn-blue:hover{
    @apply bg-blue-600 shadow-lg;
  }

  .btn-blue:disabled{
    @apply bg-gray-600 shadow-none border-gray-700 opacity-50 pointer-events-none;
  }

  .btn-error{
    @apply bg-red-500 border-red-700;
  }

  .btn-error:hover{
    @apply bg-red-600 shadow-lg;
  }


  .btn-remove{
    @apply text-red-500 text-sm;
  }

  .form-group{
    @apply mb-12;
  }

  .form-item{
    @apply mb-8;
  }

  .form-label{
    @apply block text-gray-800 text-base font-semibold mb-1;
  }

  .form-input{
    @apply placeholder-gray-400 shadow border rounded w-full py-3 px-3 text-gray-900 leading-tight bg-white border-gray-500;
  }

  .form-input-error{
    @apply bg-red-100 border-red-600;
  }

  .form-error-message{
    @apply text-red-500 text-sm mt-2;
  }


  .form-input:focus{
    @apply outline-none ring ring-blue-200 ;
  }

  .form-radio{
    @apply w-5 h-5 mr-2;
  }

  .form-radio-error{
    @apply border-red-600 bg-red-100;
  }

  .form-checkbox{
    @apply w-5 h-5 mr-2 cursor-pointer rounded border-solid border border-gray-500 text-blue-600 shadow-sm;
  }

  .form-checkbox:focus{
    @apply  border-blue-300 ring ring-blue-200;
  }


  .form-select{
    @apply  block appearance-none py-3 overflow-x-hidden leading-tight bg-white border rounded shadow border-gray-500 cursor-pointer;
  }

  .form-select-error{
    @apply bg-red-100 border-red-600;
  }

  .form-select:forcus{
    @apply outline-none bg-white ring;
  }

  .form-input-result{
    @apply  w-full py-2 text-black leading-tight;
  }

  /* .StripeElement {
    @apply block appearance-none py-3 overflow-x-hidden leading-tight bg-white border rounded shadow border-gray-500 cursor-pointer;
  } */

  .StripeElement--focus  {
    @apply outline-none ring;
  }

  .StripeElement--invalid {
    @apply border border-solid border-red-500 bg-red-100;
  }
}


/* .StripeElement {
  @apply block appearance-none py-3 overflow-x-hidden leading-tight bg-white border rounded shadow border-gray-500 cursor-text;
} */

.StripeElement--focus  {
  @apply outline-none ring ring-blue-200;
}

.StripeElement--invalid {
  @apply border border-solid border-red-500 bg-red-100;
}


.uploadcare--button{
  @apply cursor-pointer;
}

.uploadcare--button_primary{
  @apply bg-blue-500;
}

.uploadcare--widget__button.uploadcare--widget__button_type_open{
  @apply bg-red-500;
}

.uploadcare--widget__button.uploadcare--widget__button_type_open {
  @apply cursor-pointer bg-blue-500 border-blue-600 border-2 border-solid text-white font-bold py-2 px-4 rounded opacity-100 transition duration-300 leading-5;
}

.uploadcare--widget__button.uploadcare--widget__button_type_open:hover{
  @apply bg-blue-600 shadow-lg;
}

.uploadcare--widget__button.uploadcare--widget__button_type_open:disabled{
  @apply bg-gray-600 shadow-none border-gray-700 opacity-50 pointer-events-none;
}

